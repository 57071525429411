.changelog-container {
    width: 100%;
    background-color: var(--main-bg-color);
    color: var(--main-fg-color);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
}

.changelog-container > h1{
    padding-top: 100px;
    font-size: 4rem;
}


.changelog-container > hr {
    margin: 10px auto;
    width: 10%;
    height: 6px;
    border-width: 0;
    background-color: var(--main-hr-color);
    align: center;
}

.changelog-content {
    display: grid;
    line-height: 1.6;
    max-width: 1000px;
    place-items: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.changelog-content > p {
    font-size: 1.4rem;
}

.changelog-notes{
	width: 100%;
	margin-top: 20px;
	padding-left: 20px;
    align-items: left;
    justify-items: left;
}

.changelog-notes{
	font-size: 1.2rem;
}

@media screen and (max-width: 1120px) {
    .changelog-container > h1 {
        font-size: 2.5rem;
    }

    .changelog-content > p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .changelog-container > h1 {
        font-size: 1.5rem;
    }
}
