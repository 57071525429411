.blog-article-container {
    width: 100%;
    background-color: var(--main-bg-color);
    color: var(--main-fg-color);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
    font-family: Georgia,Times,Times New Roman,serif; 
}

.blog-article-content {
    display: grid;
    line-height: 1.6;
    max-width: 1120px;
    place-items: left;
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}


.blog-article-title {
    padding-left: 10px;
    padding-bottom: 12px;
}

.blog-article-title > h1 {
    font-size: 3.5rem;
}

.blog-article-title > h3 {
    color: var(--subtitle-color);
    font-size: 2rem;
}

.blog-article-hero {
    max-width: 100%;
    margin-top: 12px;
    margin-bottom: 30px;
}


.blog-article-hero > img {
    width: 100%;
    max-height: 300px;
    border-radius: 10px;
    object-fit: cover;
    
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
}

/* .blog-article-hero > canvas { */
/*     display: block; */
/*     background: black; */
/*     width: 100%; */
/*     height: 300px; */
/*     border-radius: 10px; */
/*     box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17); */
/*     -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017)); */
/*     filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017)); */
/* } */

.blog-article-content > hr {
    margin: 10px auto;
    width: 10%;
    height: 6px;
    border-width: 0;
    background-color: var(--main-hr-color); /* rgba(33, 158, 188, 1); */
    align: center;
}

.blog-article-content > p {
    font-size: 1.5rem;
}

.toc_container {
    background: var(--nord5) none repeat scroll 0 0;
    border: 1px solid var(--nord3);
    display: table;
    font-size: 1.5rem;
    margin-bottom: 1em;
    padding: 20px;
    width: auto;
}

.toc_title {
    font-weight: 700;
    text-align: center;
}

.toc_container li, .toc_container ul, .toc_container ul li{
    list-style: outside none none !important;
}

.toc_container a:link {
    text-decoration: none;
}

.toc_container a:visited {
    text-decoration: none;
}

.toc_container a:hover {
    text-decoration: none;
}

.toc_container a:active {
    text-decoration: none;
}

@media screen and (max-width: 1120px) {
    .blog-article-title > h1 {
        font-size: 2.5rem;
    }

    .blog-article-title > h3 {
        font-size: 1.5rem;
    }

    .blog-article-content > p {
        font-size: 1rem;
    }

    .toc_container {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 768px) {
    .blog-article-title > h1 {
        font-size: 1.5rem;
    }

    
    .blog-article-title > h3 {
        font-size: 1.2rem;
    }

    .blog-article-hero {
        display: none;
    }

    .toc_container {
        font-size: 1rem;
    }
}
