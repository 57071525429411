button.anchor {
    display: block;
    position: relative;
    top: -40px;
    visibility: hidden;
}

.projects-container {
    width: 100%;
    min-height: 100vh;
    background-color: var(--main-bg-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--main-fg-color);
}

.projects-container > h1 {
    padding-top: 100px;
    font-size: 4.5rem;
}

.projects-container > hr {
    margin: 10px auto;
    width: 10%;
    height: 6px;
    border-width: 0;
    background-color: var(--main-hr-color);
    align: center;
}

.projects-content {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
}

.cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
}

.cards__items {
    margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.cards__item__header {
  padding: 20px;
  padding-bottom: 0;
  font-size: 1.4rem;
  color: #333;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 16px;
  font-weight: 700;
  color: #333;
  background-color: #fc9867;/*#FF8360;*/
  box-sizing: border-box;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #252e48;
  font-size: 22px;
  line-height: 1.4;
}

.cards__item_logo {
  color: #eee;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media screen and (max-width: 1120px) {
  a.anchor {
    top: -60px
  }

  .projects-container > h1 {
    padding-top: 60px;
    font-size: 3.5rem;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}


@media screen and (max-width: 768px) {
  .projects-container > h1 {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 450px) {
  .projects-container > h1 {
    font-size: 2rem;
  }
}
