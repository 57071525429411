.todo-container {
    width: 100%;
    background-color: #eeeeee;
    color: #333;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
}

.todo-container > h1{
    padding-top: 100px;
    font-size: 4.5rem;
}

.todo-content {
    display: grid;
    line-height: 1.6;
    max-width: 1200px;
    place-items: center;
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.todo-content > p {
    font-size: 1.5rem;
}

.todo-content > h3 {
    margin-top: 20px;
    margin-bottom: 20px;
}

table {
    border-collapse: collapse;
    width: 100%;
}

td, th {
  border: 1px solid #bbbbbb;
  text-align: left;
  padding: 8px;
  word-wrap: break-word;
  max-width: 300px;
}

tr:nth-child(even).undone {
  background-color: #e3e3e3;
}

.done {
    background-color: #AFE1AF;
}

@media screen and (max-width: 1120px) {
    .todo-container > h1 {
        font-size: 2.5rem;
    }

    .todo-content > p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .todo-container > h1 {
        font-size: 1.5rem;
    }
}
