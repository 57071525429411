video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index:-1;
    margin: auto;
}

.hero-container {
    box-shadow: inset 0 0 0 2000px rgba(0,0,0,0.25);
    object-fit: contain;
    overflow: hidden;
}

.hero-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

.hero-content > h1 {
    margin-top: 10px;
    color: var(--hero-fg-color);
    font-size: 100px;
}

.hero-content > h3 {
    color: var(--hero-fg-color);
    font-size: 70px;
}

.more-chevron {
    font-size: 50px;
    color: var(--hero-chevron-color);
}

.more-chevron:hover {
    color: var(--hero-fg-color);
    transition: all 200ms ease-out;
}

@media screen and (max-width: 1120px) {
  .hero-content > h1 {
    font-size: 70px;
  }

  .hero-content > h3 {
      font-size: 40px;
  }

  .more-chevron {
      font-size: 30px;
  }
}

@media screen and (max-width: 768px) {

  .hero-content > h1 {
    font-size: 50px;
  }

  .hero-content > h3 {
    font-size: 30px;
  }

}
