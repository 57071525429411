.blog-container {
    width: 100%;
    background-color: var(--main-bg-color);
    color: var(--main-fg-color);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
}

.blog-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blog-title > h1{
    padding-top: 40px;
    font-size: 4.5rem;
}


.blog-title > hr {
    margin: 20px auto;
    width: 70%;
    height: 2px;
    border-width: 0;
    background-color: var(--main-hr-color); /* rgba(33, 158, 188, 1); */
    align: center;
}

.blog-title > p {
    max-width: 1120px;
    width: 70%;
    font-size: 1.5rem;
}

.blog-index {
    display: grid;
    line-height: 1.6;
    max-width: 1120px;
    width: 70%;
    place-items: leftcenter;
    margin: 20px;
    padding: 20px;
}

.index_items {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
}

.index_item {
    display: flex;
    flex-direction: row;
    margin: 0 0 20px 0;
    max-width: 100%;
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
}

.index_item_img_wrapper{
    width: 100%;
    max-width:100%;
}

.index_item_img_wrapper > img {
    display: block;
    width: auto;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
}

.index_item_content {
    margin: 10px;
    max-width: 70%;
}

.index_item_content > h3 {
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.index_item_content > h5 {
    font-size: 0.75rem;
    margin-bottom: 5px;
    color: var(--nord10);
}

.index_item_content > p {
    font-size: 1.2rem;
}

@media screen and (max-width: 1400px) {
    .blog-title > h1{
        font-size: 3rem;
    }

    .blog-title > p {
        font-size: 1rem;
    }

    .blog-index {
        width: 85%;
    }

    .index_item_content {
        max-width: 80%;
    }

    .index_item {
        flex-direction: row;
    }
}

@media screen and (max-width: 1000px) {
    .blog-title > h1{
        font-size: 2rem;
    }

    .blog-title > p {
        font-size: 1rem;
    }

    .blog-index {
        width: 95%;
    }

    .index_item_img_wrapper {
        visibility: hidden;
        display: none;
    }

    .index_item_content {
        max-width: 100%;
    }
}


@media screen and (max-width: 768px) {
    .blog-title > h1{
        font-size: 2rem;
    }

    .blog-index {
        width: 100%;
    }
}


