.footer-container {
  background-color: var(--footer-bg-color);
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Social Icons */
.social-icon-link {
  color: var(--footer-fg-color);
  font-size: 48px;
}

.github:hover {
  color: #fc9867;
}

.instagram:hover {
  color: #a9dc76;
}

.twitter:hover {
  color: #78dce8;
}

.linkedin:hover {
  color: #ffd866;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: auto auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  margin-top: 16px;
}

.footer-contactme {
    color: var(--footer-fg-color);
    font-size: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.social-logo {
  justify-self: start;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  max-width: 500px;
}

.footer-links {
	text-decoration: none;
}

.website-version {
  color: var(--footer-fg-color);
  margin-top: 10px;
}

.website-rights {
  color: var(--footer-fg-color);
  margin-top: 6px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
 .social-icon-link {
     font-size: 30px;
 }
 .footer-contactme {
     font-size: 16px;
 }
}
